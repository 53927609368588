import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import snippet1 from "../../../assets/images/snippet.svg";
import { useEmployee } from "../../../context/EmpProvider";
import Loader from "../../../assets/gif/Loader.gif";

import autoTable from "jspdf-autotable";
import watermark from "../../../assets/images/watermarkbw.png";
import logo from "../../../assets/images/Okulr_LogoPdf.png";
import { jsPDF } from "jspdf";

import { useCustomer } from "../../../context/CustomerProvider";
import TimeFormatter from "../../../utils/globalReportDetailsTimeFormatter";
import { useAttendanceTable } from "../../../hooks/useTime/attendanceTable";
const CompleteLog = () => {
  const [data, setData] = useState([]);
  const { filteredData, setFilteredData, completeLog, setCompleteLog,setRecordLength } =
    useEmployee();
const {getTimeByCountryfForReports}=useAttendanceTable()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(""); // New state for selected date
  const { customer } = useCustomer();
  const [companyLogo, setComplanyLogo] = useState("");
  useEffect(() => {
    // console.log("customer?.data?.businesslogo",customer?.data?.businesslogo)
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);
  useEffect(() => {
    const fetchCompleteLog = async () => {
      try {
        if (!localStorage.getItem("token")) {
          throw new Error("Customer ID is not available in localStorage");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/liveTracker/getCompleteLogOfEmployee`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        setData(response.data);
        setCompleteLog(response.data);
        setFilteredData(response.data); // Initially set filtered data to all data
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCompleteLog();
  }, []);



  useEffect(() => {
    if (selectedDate) {
      const filtered = data.map((employee) => {
        const filteredLogs = employee.logs.filter(
          (log) =>
            new Date(log.date).toISOString().split("T")[0] === selectedDate
        );
        return { ...employee, logs: filteredLogs };
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [selectedDate, data]);

  // console.log(filteredData, "filteredData");

  if (loading)
    return (
      <>
        <div className="list_of_dataLoading">
          <img src={Loader} alt="Loading" />
        </div>
      </>
    );
  if (error)
    return (
      <div className="list_of_dataLoading">
        <h1>No Employees Logs Found</h1>
      </div>
    );

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const tableHeading = [
    { id: 1, name: "S.NO" },
    { id: 2, name: "Date" },
    { id: 3, name: "Employee ID" },
    { id: 4, name: "Employee Name" },
    { id: 5, name: "Shift Timing" },
    { id: 6, name: "In-Timing" },
    { id: 7, name: "In-Timing Snippet" },
    { id: 8, name: "Out-Timing" },
    { id: 9, name: "Out-Timing Snippet" },
    { id: 10, name: "In-Office Time" },
  ];

  const formatTime = (timeString) => {
    if (!timeString) return "N/A";

    const [hours, minutes, seconds] = timeString.split(":");

    if (!hours || !minutes || !seconds) return "Invalid Time";

    const hour = parseInt(hours, 10);
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;

    return `${formattedHour}:${minutes}:${seconds} ${period}`;
  };

  const calculateOfficeTime = (inTime, outTime) => {
    if (!inTime || !outTime) return "N/A";

    const inDate = new Date(`1970-01-01T${inTime}Z`);
    const outDate = new Date(`1970-01-01T${outTime}Z`);
    if (isNaN(inDate.getTime()) || isNaN(outDate.getTime())) return "N/A";

    const totalOfficeTime = outDate - inDate;
    const hours = Math.floor(totalOfficeTime / (1000 * 60 * 60));
    const minutes = Math.floor(
      (totalOfficeTime % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((totalOfficeTime % (1000 * 60)) / 1000);

    return `${hours}hrs : ${minutes}mins : ${seconds}sec`;
  };

  const formatTimesForCell = (times) => {
    return times.map((time) => formatTime(time)).join("<br />");
  };

  let rowIndex = 0; // Initialize a counter for S.NO

  const handleLogDownload = async () => {
    try {
      const doc3 = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [297, 210], // A4 size in landscape
      });

      if (companyLogo) {
        // Load the logo image to calculate its dimensions
        const logoImage = new Image();
        logoImage.src = companyLogo; // Assuming companyLogo is a base64 encoded image

        logoImage.onload = async () => {
          const originalWidth = logoImage.width;
          const originalHeight = logoImage.height;

          // Set maximum dimensions
          const maxWidth = 70; // Adjust maximum width
          const maxHeight = 35; // Adjust maximum height

          let logoWidth = originalWidth;
          let logoHeight = originalHeight;

          // Calculate aspect ratio and adjust width/height to fit within max dimensions
          const aspectRatio = originalWidth / originalHeight;

          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }

          if (logoHeight > maxHeight) {
            logoHeight = maxHeight;
            logoWidth = maxHeight * aspectRatio;
          }

          // Add the logo to the PDF with the adjusted size
          doc3.addImage(companyLogo, "JPEG", 10, 10, logoWidth, logoHeight);

          // Continue with generating the table and the rest of the PDF
          const tableColumn3 = [
            "S.No.",
            "Date",
            "Employee ID",
            "Employee Name",
            "Shift Timing",
            "In-Timing",
            "InSnippet",
            "Out-Timing",
            "Out-Snippet",
            "In-Office Time",
          ];

          let serialNumber = 1;
          const tableRows3 = await Promise.all(
            filteredData.flatMap((employee) =>
              employee.logs.flatMap((log) => {
                const logDate = new Date(employee.inoutDate)
                  .toISOString()
                  .split("T")[0];

                return log.logEntries.map((entry) => {
                  const inTimeSnippetUrl = entry.inTimeSnippet
                    ? `data:image/png;base64,${entry.inTimeSnippet}`
                    : null;

                  const outTimeSnippetUrl = entry.outTimeSnippet
                    ? `data:image/png;base64,${entry.outTimeSnippet}`
                    : "";

                  const inOfficeTime =
                    entry.inTime && entry.outTime
                      ? calculateOfficeTime(entry.inTime, entry.outTime)
                      : "N/A";

                  return [
                    serialNumber++, // Unique index
                    logDate,
                    employee.employeeId || "--",
                    employee.employeeName || "--",
                    employee.shiftTiming || "--",
                    // formatTime(entry.inTime) || "--",

                    customer.data.country && (
                      getTimeByCountryfForReports(
                        customer.data.country,
                        customer.data.state,

                        entry.inTime
                      )
                     
                    ) || "--",


                    { imageUrl: inTimeSnippetUrl },
                    // formatTime(entry.outTime) || "--",
                    customer.data.country && (
                      getTimeByCountryfForReports(
                        customer.data.country,
                        customer.data.state,
                        entry.outTime
                      )
                     
                    ) || "--",


                    { imageUrl: outTimeSnippetUrl },
                    inOfficeTime,
                  ];
                });
              })
            )
          );

          const pageWidth = doc3.internal.pageSize.width;
          const totalTableWidth = 15 + 30 + 30 + 35 + 35 + 20 + 25 + 25 + 25; // Sum of all the column widths

          const leftMargin = (pageWidth - totalTableWidth) / 2;
          const title = "In-Out Time Attendance Summary"; // Define the title
          const textWidth = doc3.getTextWidth(title); // Get the width of the text
          const textX = (pageWidth - textWidth) / 2; // Calculate the X position for centered text

          doc3.text(title, textX, 40);

          autoTable(doc3, {
            head: [tableColumn3],
            body: tableRows3,
            startY: 50,
            margin: { top: 50, left: 10, right: 10 },
            styles: { overflow: "linebreak", cellWidth: "wrap" },
            bodyStyles: { cellPadding: { top: 8, bottom: 8, left: 2 } },
            headStyles: { cellPadding: { top: 8, bottom: 8, left: 2 } },
            columnStyles: {
              0: { cellWidth: 15 },
              1: { cellWidth: 30 },
              2: { cellWidth: 35 },
              3: { cellWidth: 35 },
              4: { cellWidth: 35 },
              5: { cellWidth: 20 },
              6: { cellWidth: 25 },
              7: { cellWidth: 30 },
              8: { cellWidth: 25 },
              9: { cellWidth: 30 },
            },
            didDrawCell: (data) => {
              if (
                (data.column.index === 6 || data.column.index === 8) &&
                data.cell.raw &&
                data.cell.raw.imageUrl
              ) {
                const imageUrl = data.cell.raw.imageUrl;

                if (imageUrl) {
                  // Add the image at the appropriate location if it exists
                  doc3.addImage(
                    imageUrl,
                    "JPEG",
                    data.cell.x + 2,
                    data.cell.y + 2,
                    24,
                    20
                  );
                }
              }
            },
            horizontalPageBreakRepeat: true,
          });

          // Save the PDF
          doc3.save(`attendance.pdf`);
        };

        logoImage.onerror = () => {
          console.error(
            "Failed to load the logo image from the base64 string."
          );
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="attendanceTable">
      <div className="list" id="pdfContent">
        <table>
          <thead>
            <tr className="tableAttendanceHeading">
              {tableHeading.map((h) => (
                <th key={h.id}>{h.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.flatMap((employee) =>
                employee.logs.flatMap((log) => {
                  const logDate = new Date(log.date)
                    .toISOString()
                    .split("T")[0];

                  const inTimes = log.logEntries
                    .map((entry) => entry.inTime)
                    .filter((time) => time);
                  const outTimes = log.logEntries
                    .map((entry) => entry.outTime)
                    .filter((time) => time);

                  return log.logEntries.map((entry, entryIndex) => {
                    rowIndex += 1; // Increment the counter for each row
                    const recordLength=rowIndex;
                    setRecordLength(recordLength);

                    return (
                      <tr
                        key={`${employee.employeeId}-${rowIndex}`}
                        className="tableDataName"
                      >
                        <td>{rowIndex}</td>
                        <td>{logDate}</td>
                        <td>{employee.employeeId}</td>
                        <td>{employee.employeeName}</td>
                        <td>{employee?.shiftTiming}</td>
                        {/* Assuming entry.inTime is in a valid date string format */}
                        <td>
                          {customer.data.country && (
                            <TimeFormatter
                              country={customer.data.country}
                              time={entry.inTime}
                            />
                          )}
                        </td>

                        <td>
                          {entry.inTimeSnippet ? (
                            <img
                              style={{ height: "42px", width: "40px" }}
                              src={`data:image/png;base64,${entry.inTimeSnippet}`}
                              alt="In Time Snippet"
                            />
                          ) : (
                            "No snippet available"
                          )}
                        </td>
                        <td>
                          {customer.data.country && entry.outTime ? (
                            <TimeFormatter
                            state={customer.data.state} 
                              country={customer.data.country}
                              time={entry.outTime}
                            />
                          ) : (
                            "NA"
                          )}
                        </td>

                        <td>
                          {entry.outTimeSnippet ? (
                            <img
                              style={{ height: "42px", width: "40px" }}
                              src={`data:image/png;base64,${entry.outTimeSnippet}`}
                              alt="Out Time Snippet"
                            />
                          ) : (
                            "No snippet available"
                          )}
                        </td>
                        <td>
                          {entry.inTime && entry.outTime
                            ? calculateOfficeTime(entry.inTime, entry.outTime)
                            : "N/A"}
                        </td>
                      </tr>
                    );
                  });
                })
              )
            ) : (
              <tr>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  <h1> No employee data found</h1>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="button_download">
        <button onClick={handleLogDownload}>
          <span>Download</span>
        </button>
      </div>
    </div>
  );
};

export default CompleteLog;
