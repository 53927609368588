import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import "./App.css";
import "./styles/colors/color.css";
import "./components/Layout/AdminLayout/layout.css";
import "./components/Layout/Navbar/navbar.css";
import "./components/Layout/Sidebar/sidebar.css";
import "./pages/reports/reportsDetails.css";
import "./pages/adminAccount/adminAcount.css";
import "./pages/liveTracker/liveTracker.css";
import "./pages/exployeeDetails/employeeDetails.css";
import "./pages/Dashboard/dashboard.css";
import "./pages/auth/login/login.css";
import "./model/logDetails/logDetails.css";
import "./model/confirm/confirm.css";
import "./model/editProfile/editProfile.css";
import Loader from "./assets/gif/Loader.gif";
import "./pages/animation/animation.css";
import "./components/reports/reportTable/attendanceTable.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import ReportsDetails from "./pages/reports/ReportsDetails";
import AdminAccount from "./pages/adminAccount/AdminAccount";
import LiveTracker from "./pages/liveTracker/LiveTracker";
import EmployeeDetails from "./pages/exployeeDetails/EmployeeDetails";
import Login from "./pages/auth/login/Login";
import ChangePassword from "./pages/auth/changePassword/ChangePassword";
import NewPassword from "./pages/auth/newPassword/NewPassword";
import Forgetpassword from "./pages/auth/forgetPassword/Forgetpassword";
import Animation from "./pages/animation/Animation";
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useCustomer } from "./context/CustomerProvider";
import RedirectPage from "./pages/redirect/RedirectPage";
import { useEmployee } from "./context/EmpProvider";

const PrivateRoute = () => {
  const { customer, isAuthenticated } = useCustomer();

  return customer && isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

function App() {
  const { setCustomer, customer, setError, setIsAuthenticated, isUpdate } =
    useCustomer();
  const {
    department,
    setDepartment,
    employment,
    setEmployment,
    designation,
    setDesignation,
    workLocation,
    setWorkLocation,
    setErrorMultipleFilter,
    setLoadingMultipleFilter,
  } = useEmployee();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
      const customerID = localStorage.getItem("CustomerID");
    if (customerID) {
      const fetchUserData = async () => {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/user/getuser`,
            {customerid:customerID}
            
          );
          // console.log(data,"data")
          setLoading(false)
          setCustomer({ ...customer, data: data });
          setIsAuthenticated(true);
        } catch (error) {
          setError("Failed to fetch user data.");
          setIsAuthenticated(false);
          setLoading(false)
        }
      };

      fetchUserData();
    } else {
      setError("No customerid found. Please log in.");
      setIsAuthenticated(false);
      setLoading(false)
    }
  }, [isUpdate]);

  useEffect(() => {

    const verifyCustomerId = async () => {
      if (localStorage.getItem("token")) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/user/verifyCustomerId`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
            }
          );

          if (response.data.exists) {
            setIsAuthenticated(true);
            setLoading(false)
          } else {
            setError("Customer ID not found. Please log in.");
          }
        } catch (error) {
          setError("Failed to verify customer ID.");
          setIsAuthenticated(false);
          setLoading(false)
        }
      } else {
        setError("No customer ID found. Please log in.");
        setLoading(false);
        setIsAuthenticated(false);
      }
    };

    verifyCustomerId();
  }, []);

  useEffect(() => {

    const customerid = localStorage.getItem("CustomerID");

    const fetchData = async () => {

      try {
        // Define the URLs based on customerId
        const departmentUrl = `${process.env.REACT_APP_BASE_URL}/masters/department/get`;
        const designationUrl = `${process.env.REACT_APP_BASE_URL}/masters/designation/get`;
        const employmentTypeUrl = `${process.env.REACT_APP_BASE_URL}/masters/employmenttype/get`;
        const workLocationUrl = `${process.env.REACT_APP_BASE_URL}/masters/location/get`;

        // Make all API requests simultaneously
        const [
          departmentRes,
          designationRes,
          employmentTypeRes,
          workLocationRes,
        ] = await Promise.all([
          axios.post(departmentUrl, { customerid: customerid }),
          axios.post(designationUrl, { customerid: customerid }),
          axios.post(employmentTypeUrl, { customerid: customerid }),
          axios.post(workLocationUrl, { customerid: customerid }),
        ]);

        // Store the responses in state

        const uniqueDepartments = departmentRes?.data?.departments.reduce(
          (acc, dept) => {
            // Normalize department name by trimming spaces
            const normalizedDepartment = dept.department.trim();

            // Check if department already exists in the accumulator
            const existingDept = acc.find(
              (d) => d.department === normalizedDepartment
            );

            if (existingDept) {
              // Update designations if not already present
              if (!existingDept.designations.includes(dept.designation)) {
                existingDept.designations.push(dept.designation);
              }
            } else {
              // Create a new entry with all keys
              acc.push({
                ...dept,
                department: normalizedDepartment,
                designations: [dept.designation],
              });
            }

            return acc;
          },
          []
        );

        // Remove the extra designations keys from the final output
        const result = uniqueDepartments.map((dept) => {
          const {
            _id,
            customerid,
            department,
            departmentattributes,
            __v,
            designations,
          } = dept;
          return {
            _id,
            customerid,
            department,
            departmentattributes,
            __v,
            designations,
          };
        });

        // setDepartment(result);
        setDepartment(
          departmentRes?.data?.departments?.map((dep) => ({
            ...dep,
            isTick: false,
          }))
        );

        setDesignation(
          designationRes?.data?.designations.map((des) => ({
            ...des,
            isTick: false,
          }))
          // departmentRes?.data?.departments?.map((dep) => ({
          //   ...dep,
          //   isTick: false,
          // }))
        );
        setEmployment(
          employmentTypeRes?.data?.employmenttypes.map((emp) => ({
            ...emp,
            isTick: false,
          }))
        );
        setWorkLocation(
          workLocationRes?.data?.locations.map((loc) => ({
            ...loc,
            isTick: false,
          }))
        );
        setLoadingMultipleFilter(false);
      } catch (error) {
        setErrorMultipleFilter(error);
      } finally {
        setLoadingMultipleFilter(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return (
      <>
        <div className="Loader">
          <img src={Loader} alt="Loading" />
        </div>
      </>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Animation />} />
          <Route path="/redirect-loader/:token" element={<RedirectPage />} />

          <Route path="/login" element={<Login />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/forgotpassword" element={<Forgetpassword />} />

          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/reports-cards" element={<ReportsDetails />} />
            <Route path="/admin-account" element={<AdminAccount />} />
            <Route path="/live-tracker" element={<LiveTracker />} />
            <Route path="/emp-details" element={<EmployeeDetails />} />
          </Route>

          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
