import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/AdminLayout/Layout";
import employeePic from "../../assets/images/employeeDetials.svg";
import searchIcons from "../../assets/images/searchIcons.svg";
import filterIcons from "../../assets/images/Filter list alt.svg";
import employeePicList from "../../assets/images/employee.svg";
import { useEmployee } from "../../context/EmpProvider";
import { useModel } from "../../hooks/useModel/useModel";
import LogDetails from "../../model/logDetails/LogDetails";
import EditProfile from "../../model/editProfile/EditProfile";
import Confirm from "../../model/confirm/Confirm";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import IdProof from "../../model/idProof/IdProof";
import Loader from "../../assets/gif/Loader.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useRef } from "react";

import search_icons from "../../assets/images/searchIcons.svg";

const EmployeeDetails = () => {
  const {
    employee,
    setEmployee,
    setError,
    employeeDetails,
    setEmployeeDetails,
    isUpdate,
    setIsUpdate,

    setWorkLocation,
    setDesignation,
    setDepartment,
    setEmployment,

    department,
    employment,
    designation,
    workLocation,
  } = useEmployee();

  const popupFilterBoxRef = useRef(null);
  const { open, handleOpen, handleClose } = useModel();
  const {
    open: open1,
    handleOpen: handleOpen1,
    handleClose: handleClose1,
  } = useModel();
  const {
    open: open2,
    handleOpen: handleOpen2,
    handleClose: handleClose2,
  } = useModel();

  const {
    open: open3,
    handleOpen: handleOpen3,
    handleClose: handleClose3,
  } = useModel();
  const [type, setType] = useState("terminate");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchFilter, setSearchFilter] = useState(false);

  const messageTerminate = {
    title: `${
      employeeDetails?.isTerminated ? "Terminated" : "Terminate"
    } Employee Account?`,
    content: `${
      employeeDetails?.isTerminated
        ? "This employee's account has already been terminated. No further action is required."
        : "Are you sure you want to permanently terminate this employee's account? This action cannot be undone."
    }`,

    leftBtn: `${employeeDetails?.isTerminated ? "" : "Terminate"}`,
    rightBtn: `${employeeDetails?.isTerminated ? "Ok" : "Cancel"}`,
    type: employeeDetails?.isTerminated ? "terminated" : "terminate",
  };

  const messageSuspend = {
    title: `${
      employeeDetails?.isTerminated
        ? "Terminated"
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    } Employee Account?`,
    content: `${
      employeeDetails?.isTerminated
        ? "This employee's account is already terminated. Suspension is not possible."
        : employeeDetails?.isSuspended
        ? "This employee's account is already suspended. You can reactivate it later if needed."
        : "Are you sure you want to suspend this employee's account? The account can be reactivated later, but the user will not have access in the meantime."
    }`,
    leftBtn: `${
      employeeDetails?.isTerminated
        ? ""
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    }`,
    rightBtn: `${
      employeeDetails?.isTerminated
        ? "Ok"
        : employeeDetails?.isSuspended
        ? "Cancel"
        : "Cancel"
    }`,
    type: `${
      employeeDetails?.isTerminated
        ? "Terminated"
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    }`,
  };

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(false);
  const [filterConditinally, setFilterConditinally] = useState([]);
  const [filterLoading, setFilterloading] = useState(false);
  const [select, setSelect] = useState({
    department: [],
    designation: [],
    employment: [],
    work_Location: [],
    index: 0,
  });
  const [current, setCurrent] = useState("Department");
  const [openFilter, setOpenFilter] = useState(false);
  const limit = 40;
  useEffect(() => {
    loadMoreEmployees();
  }, [isUpdate]);

  const onEmployeeSelect = (id) => {
    const info = employee.find((e) => e?._id === id);
    if (info) {
      setEmployeeDetails(info);
    }
  };

  const handleTerminate = () => {
    setType("terminate");

    handleOpen2();
  };
  const handleSuspended = () => {
    setType("suspend");
    handleOpen2();
  };
  const loadMoreEmployees = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeListIndividuals-customers`,
        {
          params: { page, limit, isFaceRegistered: true },
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      const { employees: newEmployees, pagination } = response.data.data;

      setEmployee((prevEmployees) => {
        const existingEmployeeIds = new Set(
          prevEmployees.map((employee) => employee._id)
        );

        const filteredNewEmployees = newEmployees.filter(
          (newEmployee) => !existingEmployeeIds.has(newEmployee._id)
        );

        return [...prevEmployees, ...filteredNewEmployees];
      });

      if (newEmployees.length > 0) {
        setEmployeeDetails(newEmployees[0]);
      }
      setLoading(false);

      setPage(pagination.currentPage + 1);
      setHasMore(pagination.hasNextPage);
    } catch (error) {
      setError("Error fetching employees");
    }
  };
  const fetchSearchResults = async (query) => {
    try {
      if (!query || query.length == 0 || !query === "") {
        setSearchFilter(false);
        return;
      }

      setLoadMoreData(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/searchEmployees?q=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      setFilteredEmployees(data?.data);
      setSearchFilter(true);
    } catch (e) {
      // console.log(e.response.merssage);
    }
  };

  // Debounced version of the fetch function
  const debouncedFetchResults = debounce((query) => {
    fetchSearchResults(query);
  }, 1000);
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedFetchResults(query);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (current) {
        if (current === "Department") {
          setFilterConditinally(department);
        } else if (current === "Designation") {
          setFilterConditinally(designation);
        } else if (current === "Employmen..") {
          setFilterConditinally(employment);
        } else if (current === "Work Location") {
          setFilterConditinally(workLocation);
        }
      }
    };
    fetchData();
  }, [current, department]);

  const handleSelect = (i) => {
    setSelect((prev) => ({
      ...prev,
      index: i,
    }));
  };

  const handleConvertCheck = (filterType, data) => {
    const updatedFilters = filterType.map((item) => {
      if (item._id === data._id) {
        return { ...item, isTick: !item.isTick };
      }
      return item;
    });

    if (current === "Department") {
      const filteredData = updatedFilters?.filter(
        (fil) => fil?.isTick === true
      );

      setSelect((prev) => ({
        ...prev,
        department: filteredData?.map((des) => des?.department.trim()),
      }));
      setDepartment(updatedFilters);
      setFilterConditinally(updatedFilters);
    } else if (current === "Designation") {
      const filteredData = updatedFilters?.filter(
        (fil) => fil?.isTick === true
      );

      setSelect((prev) => ({
        ...prev,

        designation: filteredData?.map((des) => des?.designation.trim()),
      }));
      setDesignation(updatedFilters);
      setFilterConditinally(updatedFilters);
    } else if (current === "Employmen..") {
      const filteredData = updatedFilters?.filter(
        (fil) => fil?.isTick === true
      );

      setSelect((prev) => ({
        ...prev,

        employment: filteredData?.map((emp) => emp?.employmenttype.trim()),
      }));
      setEmployment(updatedFilters);
      setFilterConditinally(updatedFilters);
    } else if (current === `Work Location`) {
      const filteredData = updatedFilters?.filter(
        (fil) => fil?.isTick === true
      );

      setSelect((prev) => ({
        ...prev,
        work_Location: filteredData?.map(
          (loc) => `${loc?.city.trim()} - ${loc?.pincode.trim()}`
        ),
      }));
      setWorkLocation(updatedFilters);
      setFilterConditinally(updatedFilters);
    }
  };

  const handleSelectCheckBox = (data) => {
    if (current === "Department") {
      handleConvertCheck(filterConditinally, data, current);
    } else if (current === "Designation") {
      handleConvertCheck(filterConditinally, data, current);
    } else if (current === "Employmen..") {
      handleConvertCheck(filterConditinally, data, current);
    } else if (current === "Work Location") {
      handleConvertCheck(filterConditinally, data, current);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupFilterBoxRef.current &&
        !popupFilterBoxRef.current.contains(event.target)
      ) {
        setOpenFilter(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openFilter]);

  const applyFilter = async () => {
    if (
      (!select?.department || select?.department.length === 0) &&
      (!select?.designation || select?.designation.length === 0) &&
      (!select?.employment || select?.employment.length === 0) &&
      (!select?.work_Location || select?.work_Location.length === 0)
    ) {
      return;
    }

    const filterData = {};

    if (select?.department && select?.department.length > 0) {
      filterData.department = select.department;
    }

    if (select?.designation && select?.designation.length > 0) {
      filterData.designation = select.designation;
    }

    if (select?.employment && select?.employment.length > 0) {
      filterData.employment = select.employment;
    }

    if (select?.work_Location && select?.work_Location.length > 0) {
      filterData.work_Location = select.work_Location;
    }

    setFilterloading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/employees/filter-conditionally`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success) {
        setFilteredEmployees(response?.data?.data);
        setSearchFilter(true);
        setOpenFilter(false);
        handleClearAll("applyFilter");
        setFilterloading(false);
      }
    } catch (error) {
      setFilterloading(false);
    }
  };
  const handleClearAll = (check = "clearAll") => {
    if (check !== "applyFilter") {
      // console.log(employee);
      setSearchFilter(false);
    }
    setDepartment(
      department?.map((dep) => ({
        ...dep,
        isTick: false,
      }))
    );
    setEmployment(
      employment?.map((emp) => ({
        ...emp,
        isTick: false,
      }))
    );
    setWorkLocation(
      workLocation?.map((wor) => ({
        ...wor,
        isTick: false,
      }))
    );
    setDesignation(
      designation?.map((des) => ({
        ...des,
        isTick: false,
      }))
    );
    setCurrent("Department");
    setFilterConditinally(
      department?.map((dep) => ({
        ...dep,
        isTick: false,
      }))
    );
    setSelect((prev) => ({
      department: [],
      designation: [],
      employment: [],
      work_Location: [],
      index: 0,
    }));
  };
  return (
    <Layout>
      {/* {loading && (
        <>
          <div className="Loader">
            <img src={Loader} alt="Loading" />
          </div>
        </>
      )} */}

      {/* employee.length <= 0 && (
            <div className="list_of_dataLoading">
              <h1>No Employee Details Found</h1>
            </div>
          )}
          {loading ? (
            <div className="liveTrackerLoder">
              <img src={Loader} alt="Loading" />
            </div> */}

      <div className="employeeDetails">
        <div className="one">
          <h3>All Employees </h3>
        </div>

        <div className="commonBorderForAll employeeDetailsSection">
          {loading ? (
            <div
              className="liveTrackerLoader "
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Loader} alt="Loading" height={100} width={100} />
            </div>
          ) : employee.length === 0 ? (
            <div className="list_of_dataLoading">
              <h1>No Employee Details Found</h1>
            </div>
          ) : (
            <>
              {(filteredEmployees.length > 0 || employee.length > 0) && (
                <div className="DetailsOfEmployeeLeft">
                  <div className="Employees_Whole_Data">
                    <div className="Employees_Data_Heading">
                      <div className="Heading_Panel">
                        <h1>Employee Details</h1>
                      </div>
                      <div className="Heading_Panel_A">
                        {/* <div class="Emp_Action_Button" onClick={handleTerminate}>
          <p>
            <span>
              {employeeDetails?.isTerminated
                ? " Terminated"
                : " Terminate"}
            </span>
          </p>
        </div>
        <div class="Emp_Action_Button" onClick={handleSuspended}>
          <p>
            <strong>
              {employeeDetails?.isSuspended
                ? " Suspended"
                : " Suspend"}
            </strong>
          </p>
        </div> */}
                        <div
                          className="Emp_Action_Button"
                          onClick={handleOpen1}
                        >
                          <p>Edit</p>
                        </div>
                      </div>
                    </div>

                    <div className="containerEmpDetails">
                      <div className="Profile_Data">
                        <div className="profilePic">
                          <img
                            src={employeeDetails?.profilePhoto}
                            alt={`${employeeDetails?.idProofPhoto?.name}'s picture`}
                          />
                          <button className="logDetails" onClick={handleOpen}>
                            <span>Log Details</span>
                          </button>
                        </div>
                      </div>
                      <div className="Profile_Container">
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>First Name</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.firstName}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Last Name</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.lastName}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Employee ID</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.employeeId}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>D.O.B</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>
                              {new Date(
                                employeeDetails?.dob
                              ).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Gender</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.gender}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Email ID (Personal)</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.emailPersonal}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Email ID (Official)</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.emailOfficial}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Mobile No.</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.mobileNo}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="Profile_Details">
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>Address</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.address}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>Date of Joining</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>
                          {new Date(
                            employeeDetails?.joiningDate
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>Department</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.department}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>IT</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.designation}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>Employment Type</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.employmentType}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>Work Location</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.workLocation}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>Block No./Building No.</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.blockOrBuildingNo}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>Shift</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.shift}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>ID Proof</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.idProofType}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>ID Proof No.</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div className="Profile_Content_Data">
                        <p>{employeeDetails?.idProofNo}</p>
                      </div>
                    </div>
                    <div className="Profile_Content">
                      <div className="Profile_Content_Heading">
                        <p>ID Proof Photo</p>
                      </div>
                      <div className="T11">
                        <p>:</p>
                      </div>
                      <div
                        className="Profile_Content_Data"
                        onClick={handleOpen3}
                      >
                        <p>
                          <a
                            href={employeeDetails?.idProofPhoto?.data || "#"} // Use the data URL or a placeholder if data is not available
                            download={`${
                              employeeDetails?.firstName || "Employee"
                            }_ID.png`} // Default name if firstName is empty
                          >
                            Download ID Proof
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="listOfEmployeeRight">
            <div className="All_Employees_List">
              {(filteredEmployees.length > 0 || employee.length > 0) && (
                <div className="EmployeeSearch">
                  <form
                    className="SearchForm"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="Search_Input_Data">
                      <div
                        className="searchBox"
                        style={{
                          width: "100%",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Enter Employee Name"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          style={{
                            "--search-icon": `url(${search_icons})`,
                            width: "100%",
                          }}
                        />
                      </div>
                      <div className="Input_Data_Button">
                        {openFilter && (
                          <div className="model_filter" ref={popupFilterBoxRef}>
                            <div className="heading">
                              <h3 style={{ cursor: "pointer" }}>Select List</h3>
                              <h3
                                onClick={handleClearAll}
                                style={{ cursor: "pointer" }}
                              >
                                Clear All
                              </h3>
                            </div>
                            <div className="filterBasedOn">
                              <div className="name">
                                {[
                                  "Department",
                                  "Designation",
                                  "Employmen..",
                                  "Work Location",
                                ].map((based, i) => (
                                  <div
                                    className={`nameOfThat ${
                                      i === select?.index && "skyBlue"
                                    }`}
                                    key={i}
                                    onClick={() => {
                                      handleSelect(i);
                                      setCurrent(based);
                                    }}
                                  >
                                    <p>{based}</p>
                                  </div>
                                ))}
                              </div>
                              <div className="borderCenter"></div>
                              <div className="nameOfChoosen">
                                {filterConditinally?.map((v, i) => (
                                  <div className="checkbox_container" key={i}>
                                    <div
                                      className="iconsCheckBox"
                                      onClick={() => handleSelectCheckBox(v)}
                                    >
                                      {v?.isTick ? (
                                        <MdCheckBox fontSize={22} />
                                      ) : (
                                        <MdCheckBoxOutlineBlank fontSize={22} />
                                      )}
                                    </div>

                                    <div className="nameDesig">
                                      {current === "Department" && (
                                        <p>{v?.department}</p>
                                      )}
                                      {current === "Designation" && (
                                        <p>{v?.designation}</p>
                                      )}
                                      {current === "Employmen.." && (
                                        <p>{v?.employmenttype}</p>
                                      )}

                                      {current === "Work Location" && (
                                        <p>{v?.city}</p>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="filterBtn">
                              <button onClick={applyFilter}>
                                {filterLoading ? (
                                  <span>Filtering</span>
                                ) : (
                                  <span>Apply Filter</span>
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="Search_Filter_Data"
                      onClick={() => setOpenFilter((prev) => !prev)}
                    >
                      <img src={filterIcons} alt="Filter" />
                    </div>
                  </form>
                </div>
              )}

              <div className="Employees_Lists">
                {searchFilter ? (
                  filteredEmployees.length > 0 ? (
                    filteredEmployees.map((data, i) => (
                      <div
                        key={i}
                        className="Employee_List_Data"
                        onClick={() => onEmployeeSelect(data._id)}
                        tabIndex="0"
                        style={{ padding: "10px" }}
                      >
                        <div className="Employee_Picture">
                          <img
                            src={data?.profilePhoto}
                            alt={`${data?.firstName} ${data?.lastName}'s picture`}
                          />
                        </div>
                        <div className="Employee_Content">
                          <h1 style={{ margin: "0 0 7px 0" }}>
                            {data?.firstName} {data?.lastName}
                          </h1>
                          <p style={{ margin: "0 0 7px 0" }}>
                            <strong style={{ fontWeight: "bold" }}>
                              {data?.designation}
                            </strong>
                          </p>
                          <p style={{ margin: "0 0 7px 0" }}>
                            {data?.emailOfficial}
                          </p>
                          <p style={{ margin: "0" }}>{data?.mobileNo}</p>
                        </div>
                        <div className="status_employee">
                          {data?.isTerminated && (
                            <div className="circle_status1"></div>
                          )}
                          {!data?.isTerminated && data?.isSuspended && (
                            <div className="circle_status2"></div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{ padding: "10px", textAlign: "center" }}>
                      No employee found
                    </div>
                  )
                ) : (
                  // <InfiniteScroll
                  //   dataLength={employee.length}
                  //   next={loadMoreEmployees}
                  //   hasMore={hasMore}
                  //   height={750}
                  //   loader={
                  //     <img
                  //       src={Loader}
                  //       alt="Loading"
                  //       style={{
                  //         width: "50px",
                  //         height: "50px",
                  //         display: "block",
                  //         margin: "0 auto",
                  //       }}
                  //     />
                  //   }
                  // >
                  //   {

                  employee.map((data, i) => (
                    <div
                      key={i}
                      className="Employee_List_Data"
                      onClick={() => onEmployeeSelect(data._id)}
                      tabIndex="0"
                      style={{ padding: "10px" }}
                    >
                      <div className="Employee_Picture">
                        <img
                          src={data?.profilePhoto}
                          alt={`${data?.idProofPhoto?.name}'s picture`}
                        />
                      </div>
                      <div className="Employee_Content">
                        <h1 style={{ margin: "0 0 7px 0" }}>
                          {data?.firstName} {data?.lastName}
                        </h1>
                        <p style={{ margin: "0 0 7px 0" }}>
                          <strong style={{ fontWeight: "bold" }}>
                            {data?.designation}
                          </strong>
                        </p>
                        <p style={{ margin: "0 0 7px 0" }}>
                          {data.emailOfficial}
                        </p>
                        <p style={{ margin: "0" }}>{data.mobileNo}</p>
                      </div>
                      <div className="status_employee">
                        {data?.isTerminated && (
                          <div className="circle_status1"></div>
                        )}

                        {!data?.isTerminated && data?.isSuspended && (
                          <div className="circle_status2"></div>
                        )}
                      </div>
                    </div>
                  ))

                  //   }
                  // </InfiniteScroll>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogDetails
        open={open}
        handleClose={handleClose}
        employeeId={employeeDetails.employeeId}
        employeeName={employeeDetails.firstName}
      />
      <EditProfile
        open={open1}
        handleClose={handleClose1}
        setEmployeeDetails={setEmployeeDetails}
        employeeDetails={employeeDetails}
      />
      <Confirm
        open={open2}
        handleClose={handleClose2}
        message={type === "terminate" ? messageTerminate : messageSuspend}
        setEmployeeDetails={setEmployeeDetails}
        employeeDetails={employeeDetails}
      />
      <IdProof
        open={open3}
        handleClose={handleClose3}
        idPic={employeeDetails?.idProofPhoto?.data}
      />
    </Layout>
  );
};

export default EmployeeDetails;
