import React, { useState, useEffect } from "react";
import countriesTimezones from "./countriesTimezones.json"; // Adjust the path as necessary

const TimeFormatter = ({ country }) => {
  const [timeInfo, setTimeInfo] = useState({ time: '', locale: '', timeZone: '' });

  const getTimeByCountry = (country) => {
    const countryInfo = countriesTimezones[country.toLowerCase()] || {};

    const locale = countryInfo.locale || 'en-US'; // Default locale
    const timeZone = countryInfo.timeZone || 'UTC'; // Default timezone

    try {
      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: 'long', // Shows hours, minutes, and seconds
        dateStyle: undefined // Exclude date from the formatting
      }).format(new Date());

      // Remove the timezone abbreviation (last part of the formatted string)
      return {
        time: formattedTime.replace(/\s+[A-Z]+$/, '').trim(), // Removes the last word that consists of uppercase letters
        locale,
        timeZone
      };
    } catch (error) {
      console.error('Invalid locale or timezone:', error);
      return { time: '', locale: '', timeZone: '' };
    }
  };

  // Use useEffect to update the time every second
  useEffect(() => {
    const updateTime = () => {
      setTimeInfo(getTimeByCountry(country));
    };

    // Initial time setting
    updateTime();

    // Set an interval to update the time every second
    const intervalId = setInterval(updateTime, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [country]); // The effect will run every time the country prop changes

  const { time, locale: currentLocale, timeZone } = timeInfo;

  return (
    <div>
      {/* Displaying all information in one line */}
      <div>
        Current Time: {time} | Locale: {currentLocale} | Time Zone: {timeZone}
      </div>
    </div>
  );
};

export default TimeFormatter;
