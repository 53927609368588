import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { useCustomer } from "../../context/CustomerProvider";
import { useEmployee } from "../../context/EmpProvider";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: 0,
  outline: 0,
};
const Confirm = ({
  open,
  handleClose,
  message,
  setEmployeeDetails = () => {},
  employeeDetails = null,
}) => {
  const navigate = useNavigate();
  const { customer, setCustomer } = useCustomer();
  const { handleTerminated, handleSuspended, setEmployee } = useEmployee();
  const [loading, setLoading] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("CustomerID");
     localStorage.removeItem("token");
    setCustomer({
      isLogin: false,
      token: "",
      customerId: "",
      data: null,
    });
    setEmployee([]);
    navigate("/login");
  };

  const deleteRecords = async () => {
    try {
      const customerID = localStorage.getItem("CustomerID");
      setLoading(true);
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/delete-record`,
        {
          data: { customerID: "OKFS2" },
        }
      );
      if (data?.success) {
        // alert("Record deleted successfully");
        setLoading(false);

        handleClose();
      }
      // setUserData(response.data);
    } catch (error) {
      setLoading(false);

      handleClose();
      console.error("Error fetching user data:", error);
    }
  };

  const handleConfirm = async () => {
    console.log(message?.type);
    if (message?.type === "Logout") {
      handleLogout();
    } else if (message?.type === "Warning") {
      deleteRecords();
    } else if (message?.type === "terminate") {
      try {
        const isSuccess = await handleTerminated(employeeDetails);
        if (isSuccess) {
          handleClose();
        }
      } catch (e) {
        handleClose();
        console.log(e);
      }
    } else if (message?.type === "terminate") {
      return;
    } else if (message?.type === "Suspend" || message?.type === "Suspended") {
      try {
        const type = message?.type;
        const isSuccess = await handleSuspended(employeeDetails, type);
        if (isSuccess) {
          handleClose();
        }
      } catch (e) {
        handleClose();
        // console.log(e);
      }
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="Popup_Box">
          <div className="Popup_Text">
            <h1>{message?.title}</h1>
          </div>
          <div className="contentAlter">
            <p>{message?.content}</p>
          </div>

          <div className="Popup_Button">
            <button className="loginBtn" onClick={handleConfirm}>
              {loading ? "Loading.." : `${message?.leftBtn}`}
            </button>
            <button
              className="cancelBtn"
              onClick={() => {
                handleClose();
              }}
            >
              {message?.rightBtn}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default Confirm;
