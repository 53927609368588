import CryptoJS from "crypto-js";

// Function to encrypt and store customerId in localStorage
export const storeCustomerId = (customerId) => {
  const secretKey = "your-secret-key"; // Use a secure key
  const encryptedCustomerId = CryptoJS.AES.encrypt(
    customerId,
    secretKey
  ).toString();
  localStorage.setItem("encryptedCustomerId", encryptedCustomerId);
};

// Function to get and decrypt the customerId from localStorage
export const getCustomerId = () => {
  const secretKey = "your-secret-key"; // Same key used for encryption
  const encryptedCustomerId = localStorage.getItem("encryptedCustomerId");

  if (encryptedCustomerId) {
    const bytes = CryptoJS.AES.decrypt(encryptedCustomerId, secretKey);
    const decryptedCustomerId = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedCustomerId;
  }

  return null; // Return null if not found
};
