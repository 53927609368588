import React from "react";
import logo from "../../../assets/images/Group.svg";
import userLogo from "../../../assets/images/userLogo.svg";
import account_icons from "../../../assets/images/account_icons.svg";
import { useNavigate } from "react-router-dom";
import Confirm from "../../../model/confirm/Confirm";
import { useModel } from "../../../hooks/useModel/useModel";
import { useCustomer } from "../../../context/CustomerProvider.jsx";
const message = {
  title: "Sure you need to logout?",
  content: "You need to enter your password while login again.",
  leftBtn: "Logout",
  rightBtn: "Cancel",
  type: "Logout",
};
const Navbar = () => {
  const naviagte = useNavigate();
  const { open, handleClose, handleOpen } = useModel();
  const { customer } = useCustomer();

  return (
    <div className="navbar">
      <div className="left_nav">
        <img src={logo} alt="logo" />
      </div>
      <div className="right_nav">
        <div
          className="menu_nav subMenu_nav"
          onClick={() => naviagte("/admin-account")}
        >
          <div className="one">
            <span>
             
              <b>
              {customer?.data?.businessname?.split(" ").slice(0, 2).join(" ")}
              {customer?.data?.businessname?.split(" ").length > 2 && "..."}{" "}
              </b>
            </span>
          </div>
          <div className="two">
            <img
              src={customer?.data?.businesslogo}
              alt="user"
              className="logoCompanyNavbar"
            />
            {/* <div className="userNameInLogo">
              {customer?.data?.firstname[0]?.toUpperCase()}{" "}
              {customer?.data?.lastname[0]?.toUpperCase()}
            </div> */}
          </div>
        </div>
        <div className="menu_nav">
          <img src={account_icons} alt="account" onClick={handleOpen} />
        </div>
      </div>
      <Confirm open={open} handleClose={handleClose} message={message} />
    </div>
  );
};

export default Navbar;
